import React from "react";
import type {HeadFC, PageProps} from "gatsby"
import Layout from "@components/layout";
import {graphql, Link} from "gatsby";
import {GatsbyImage, getImage} from "gatsby-plugin-image";
import CommonPageHeader from "@components/common/CommonPageHeader/CommonPageHeader";
import BlockTitle from "@components/renderer/block-title";
import BlockDescription from "@components/renderer/block-description";
import DocPlannerIcon from "@assets/svg/doc-planner-icon.inline.svg";
import GoogleMapReact from 'google-map-react';

const EmployerDetailPage: React.FC<PageProps> = ({data, pageContext}: any) => {

    const employer = data.strapiEmployer;

    const isClient = typeof window !== 'undefined';

    const seo = {
        metaTitle: employer.name,
        metaDescription: employer.description,
        shareImage: employer.cover,
    }

    const getRenderer = (descriptor, index) => {
        switch (descriptor.strapi_component) {
            case "shared.rich-text":
                return <BlockDescription key={index} data={descriptor} />
            case "shared.title":
                return <BlockTitle key={index} data={descriptor} />
        }
    }

    /*const defaultProps = {
        center: {
            lat: 10.99835602,
            lng: 77.01502627
        },
        zoom: 11
    };*/

    return (
        <Layout>
            <CommonPageHeader title="Nasz Zespół" subtitle="O Nas" />
            <section className="doctor-details-area pt-115 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-7 col-lg-8">
                            <article className="doctor-details-box">
                                <div className="service-details-text mb-40">
                                    <p>{ employer.EmployerDescription?.map((_, index) => (
                                        getRenderer(_, index)
                                    )) }</p>
                                </div>

                                { !!employer.znanylekarz_url && (
                                    <div className="service-details-text mb-40">
                                        <a
                                            className="primary_btn header_primary_btn btn-zl" target={"_blank"} href={employer.znanylekarz_url} style={{
                                            display: "inline-flex",
                                            alignItems: "center",
                                        }}>
                                            <DocPlannerIcon class={"path-color-white"}  style={{marginTop: "-4px", width: "24px", marginRight: "10px",} }/>
                                            <span>Umów wizytę</span>
                                            <i className="fa fa-angle-right" style={{marginLeft: "10px"}}></i>
                                        </a>
                                    </div>
                                ) }

                               {/* {
                                    isClient && (<div className={"service-details-text service-map"}>
                                        <GoogleMapReact
                                            bootstrapURLKeys={{ key: "" }}
                                            defaultCenter={defaultProps.center}
                                            defaultZoom={defaultProps.zoom}
                                        />
                                    </div>)
                                }*/}

                                {/*<div className="service-details-feature fix mb-30">
                                    <div className="ser-fea-box f-left">
                                        <div className="ser-fea-icon f-left">
                                            <img src="img/services/ser-fea-icon-1.png" alt="" />
                                        </div>
                                        <div className="ser-fea-list fix">
                                            <h3>Personal care</h3>
                                            <ul>
                                                <li><i className="fas fa-check"></i>Cillum dolore eu fugiat nulla.</li>
                                                <li><i className="fas fa-check"></i>Lorem ipsum dolor sit amet.</li>
                                                <li><i className="fas fa-check"></i>Consectetur adipisicing elit,</li>
                                                <li><i className="fas fa-check"></i>Sed do eiusmod tempor inci.</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="ser-fea-box f-left me-0">
                                        <div className="ser-fea-icon f-left">
                                            <img src="img/services/ser-fea-icon-2.png" alt="" />
                                        </div>
                                        <div className="ser-fea-list fix">
                                            <h3>Lifestyle support</h3>
                                            <ul>
                                                <li><i className="fas fa-check"></i>Didunt ut labore et dolore magna.</li>
                                                <li><i className="fas fa-check"></i>Aliqua. Ut enim ad minim veniam.</li>
                                                <li><i className="fas fa-check"></i>Quis nostrud exercitation ullamco.</li>
                                                <li><i className="fas fa-check"></i>Laboris nisi ut aliquip ex ea.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div id="contact-map" className="service-map mb-55">

                                    <div style={{ width: '100%' }}>
                                        <iframe title="Contact" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d58403.623581008454!2d90.32726121307132!3d23.81054442359749!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755c0c1c61277db%3A0xc7d18838730e2e59!2z4Kau4Ka_4Kaw4Kaq4KeB4KawLCDgpqLgpr7gppXgpr4!5e0!3m2!1sbn!2sbd!4v1643621605621!5m2!1sbn!2sbd" style={{ width: '100%' }} height={400} allowFullScreen={true} loading="lazy"></iframe>
                                    </div>

                                </div>*/}
                            </article>
                        </div>
                        <div className="col-xl-5 col-lg-4">
                            <div className="service-widget mb-50">
                                <div className="team-wrapper team-box-2 team-box-3 text-center mb-30">
                                    <div className="team-thumb">
                                        <GatsbyImage
                                            image={getImage(employer.cover?.localFile || null)}
                                            alt={employer.cover?.alternativeText}
                                        />
                                    </div>
                                    <div className="team-member-info mt-35">
                                        <h3>{employer.name}</h3>
                                        <h6 className="f-500 text-up-case letter-spacing color-employer-text">{employer.title}</h6>
                                    </div>
                                    <p className={"employer-card-socials mt-15 mb-15"}>
                                        {
                                            !!employer.nr_telefonu && (
                                                <span>
                                                    <Link to={"tel:" + employer.nr_telefonu} target={"_blank"}>
                                                        <i className={"fa fa-phone-alt"} />
                                                    </Link>
                                                </span>
                                            )
                                        }
                                        {
                                            !!employer.facebook_url && (
                                                <span>
                                                    <Link to={employer.facebook_url} target={"_blank"}>
                                                        <i className={"fab fa-facebook-f"} />
                                                    </Link>
                                                </span>
                                            )
                                        }
                                        {
                                            !!employer.instagram_url && (
                                                <span>
                                                    <Link to={employer.instagram_url} target={"_blank"}>
                                                        <i className={"fab fa-instagram"} />
                                                    </Link>
                                                </span>
                                            )
                                        }
                                        {
                                            !!employer.znanylekarz_url && (
                                                <span>
                                                    <Link to={employer.znanylekarz_url} target={"_blank"}>
                                                        <DocPlannerIcon style={{marginTop: "-8px"} }/>
                                                    </Link>
                                                </span>
                                            )
                                        }
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export const pageQuery = graphql`
    query ($slug: String) {
        strapiEmployer(slug: { eq: $slug }) {
            strapi_id
            name
            slug
            title
            specjalizacja
            nr_telefonu
            facebook_url
            instagram_url
            znanylekarz_url
            description {
                data {
                    description
                }
            }
            publishedAt
            cover {
                localFile {
                    childImageSharp {
                        gatsbyImageData(
                            width: 370
                            height: 420
                            placeholder: DOMINANT_COLOR
                            formats: [AUTO, WEBP]
                        )
                    }
                }
            }
            EmployerDescription {
                ... on STRAPI__COMPONENT_SHARED_RICH_TEXT {
                    strapi_component
                    childMarkdownRemark {
                        data {
                            childMarkdownRemark
                        }
                    }
                }
                ... on STRAPI__COMPONENT_SHARED_TITLE {
                    title
                    strapi_component
                }
            }
        }
    }
`

export default EmployerDetailPage

export const Head: HeadFC = () => <title>Fizjoproject Kiekrz - O Nas</title>
