import React from "react"

const BlockDescription = ({ data }) => {
    return (
        <div className="service-details-text mb-40">
            <p>{ data.childMarkdownRemark.data.childMarkdownRemark }</p>
        </div>
    )
}

export default BlockDescription