import React from "react"

const BlockTitle = ({ data }) => {
    return (
        <div className="section-text pos-rel">
            <h1>{ data.title }.</h1>
        </div>
    )
}

export default BlockTitle